import React from 'react';
import { ArrowRight, ChevronDown, Twitter, MessageCircle, Globe } from 'lucide-react';

const KaspaCashWebsite = () => {
  const stats = [
    { label: 'Total Supply', value: '28.7B KCASH' },
    { label: 'Block Reward', value: '500 KCASH' },
    { label: 'Halving Factor', value: '1/12' },
    { label: 'Launch Date', value: '2024 Q4' }
  ];

  const features = [
    'Lightning-fast transaction speeds',
    'Fair mining distribution',
    'Enhanced security protocols',
    'Community-driven development'
  ];

  const roadmap = [
    {
      phase: 'Phase 1: Foundation',
      date: 'Q4 2024',
      items: [
        'Mainnet Launch',
        'Mining Protocol Implementation',
        'Basic Wallet Release',
        'Initial Exchange Listings'
      ]
    },
    {
      phase: 'Phase 2: Expansion',
      date: 'Q1-Q2 2025',
      items: [
        'Mobile Wallet Development',
        'Advanced Mining Software',
        'Smart Contract Integration',
        'Security Audits'
      ]
    },
    {
      phase: 'Phase 3: Ecosystem',
      date: 'Q3-Q4 2025',
      items: [
        'DeFi Platform Integration',
        'Cross-chain Bridge Development',
        'Enterprise Solutions',
        'Governance Implementation'
      ]
    }
  ];

  const miningFeatures = [
    {
      title: 'GPU Mining',
      description: 'Optimized for modern graphics cards with efficient hashing algorithm',
      icon: <ChevronDown size={24} className="text-blue-600" />
    },
    {
      title: 'Fair Distribution',
      description: 'No pre-mine, no ICO, just pure mining rewards',
      icon: <ChevronDown size={24} className="text-blue-600" />
    },
    {
      title: 'Mining Pools',
      description: 'Multiple pool options available globally',
      icon: <ChevronDown size={24} className="text-blue-600" />
    }
  ];

  const faqItems = [
    {
      q: 'What is KASPA-CASH?',
      a: 'KASPA-CASH is a next-generation cryptocurrency built on BlockDAG technology, offering fast transactions and fair mining distribution.'
    },
    {
      q: 'How can I start mining?',
      a: 'You can start mining KASPA-CASH using your GPU through one of our supported mining pools. Detailed guides are available in our documentation.'
    },
    {
      q: 'What makes KASPA-CASH unique?',
      a: 'KASPA-CASH combines proven BlockDAG architecture with enhanced features for scalability, making it ideal for both miners and users.'
    },
    {
      q: 'Is there a pre-mine?',
      a: 'No, KASPA-CASH features a 100% fair launch with no pre-mine, ensuring equal opportunity for all participants.'
    }
  ];

  const communityLinks = [
    { title: 'Website', link: 'http://kaspa-cash.org', icon: <Globe size={24} /> },
    { title: 'Telegram', link: 'https://t.me/kaspacashorg', icon: <MessageCircle size={24} /> },
    { title: 'Discord', link: 'https://discord.gg/Mh4e8DHT', icon: <MessageCircle size={24} /> },
    { title: 'Twitter', link: '@KaspaCashorg', icon: <Twitter size={24} /> }
  ];

  return (
    <div className="min-h-screen bg-black text-gray-400">
      {/* Navigation */}
      <nav className="sticky top-0 z-50 backdrop-blur-xl border-b border-gray-900">
        <div className="container mx-auto px-6 py-6">
          <div className="flex justify-between items-center">
            <div className="text-3xl font-bold text-gray-300 hover:text-blue-600 transition-colors">
              KASPA-CASH
            </div>
            <div className="hidden md:flex space-x-12">
              {['Features', 'Tokenomics', 'Roadmap', 'Community'].map((item) => (
                <button key={item} className="text-gray-500 hover:text-gray-300 transition-colors text-lg">
                  {item}
                </button>
              ))}
            </div>
            <button className="bg-blue-950 hover:bg-blue-900 px-8 py-3 rounded-xl flex items-center gap-2 font-semibold transition-all hover:scale-105 text-gray-300">
              Launch App <ArrowRight size={16} />
            </button>
          </div>
        </div>
      </nav>

      {/* Hero Section */}
      <section className="py-32 relative">
        <div className="container mx-auto px-6">
          <div className="text-center max-w-4xl mx-auto">
            <h1 className="text-7xl md:text-8xl font-bold mb-8 text-gray-300">
              The Future of <span className="text-blue-600">BlockDAG</span>
            </h1>
            <p className="text-2xl text-gray-500 mb-12">
              Experience lightning-fast transactions with next-generation blockchain technology
            </p>
            <div className="flex flex-col md:flex-row gap-6 justify-center">
              <button className="group bg-blue-950 hover:bg-blue-900 px-8 py-4 rounded-xl transition-all hover:scale-105 text-gray-300">
                Start Mining <ArrowRight className="inline ml-2 group-hover:translate-x-1 transition-transform" size={16} />
              </button>
              <button className="px-8 py-4 rounded-xl border border-gray-800 text-gray-500 hover:text-gray-300 hover:border-blue-900 transition-colors">
                View Documentation
              </button>
            </div>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="py-20 bg-gradient-to-b from-black to-gray-950">
        <div className="container mx-auto px-6">
          <h2 className="text-4xl font-bold mb-16 text-center text-gray-300">
            Mining <span className="text-blue-600">Features</span>
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            {miningFeatures.map((feature) => (
              <div key={feature.title} 
                   className="bg-gray-950/50 backdrop-blur-xl p-8 rounded-2xl border border-gray-900 hover:border-blue-900 transition-all group">
                <div className="bg-blue-600/20 p-4 rounded-xl w-fit mb-6 group-hover:scale-110 transition-transform">
                  {feature.icon}
                </div>
                <h3 className="text-xl font-bold text-gray-300 mb-2">{feature.title}</h3>
                <p className="text-gray-500">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Roadmap */}
      <section className="py-32">
        <div className="container mx-auto px-6">
          <h2 className="text-4xl font-bold mb-16 text-center text-gray-300">
            Development <span className="text-blue-600">Roadmap</span>
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            {roadmap.map((phase) => (
              <div key={phase.phase} 
                   className="bg-gray-950/50 backdrop-blur-xl p-8 rounded-2xl border border-gray-900 hover:border-blue-900 transition-all group">
                <div className="text-blue-600 mb-2">{phase.date}</div>
                <h3 className="text-2xl font-bold mb-6 text-gray-300">{phase.phase}</h3>
                <ul className="space-y-4">
                  {phase.items.map((item) => (
                    <li key={item} className="flex items-center gap-3 text-gray-500">
                      <ChevronDown className="text-blue-600" size={20} />
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* FAQ */}
      <section className="py-20 bg-gradient-to-b from-gray-950 to-black">
        <div className="container mx-auto px-6">
          <h2 className="text-4xl font-bold mb-16 text-center text-gray-300">
            Frequently Asked <span className="text-blue-600">Questions</span>
          </h2>
          <div className="grid md:grid-cols-2 gap-8">
            {faqItems.map((item) => (
              <div key={item.q} 
                   className="bg-gray-950/50 backdrop-blur-xl p-8 rounded-2xl border border-gray-900 hover:border-blue-900 transition-all">
                <h3 className="text-xl font-bold text-gray-300 mb-4">{item.q}</h3>
                <p className="text-gray-500">{item.a}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Community */}
      <section className="py-32">
        <div className="container mx-auto px-6">
          <h2 className="text-4xl font-bold mb-16 text-center text-gray-300">
            Join Our <span className="text-blue-600">Community</span>
          </h2>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
            {communityLinks.map((item) => (
              <a
                key={item.title}
                href={item.link}
                className="group bg-gray-950/50 backdrop-blur-xl p-8 rounded-2xl border border-gray-900 hover:border-blue-900 transition-all"
              >
                <div className="flex flex-col items-center gap-6">
                  <div className="text-blue-600 group-hover:scale-110 transition-transform">
                    {item.icon}
                  </div>
                  <h3 className="text-lg font-semibold text-gray-500 group-hover:text-gray-300">
                    {item.title}
                  </h3>
                </div>
              </a>
            ))}
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="py-12 border-t border-gray-900">
        <div className="container mx-auto px-6 text-center text-gray-600">
          <p>&copy; 2024 KASPA-CASH. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default KaspaCashWebsite;
